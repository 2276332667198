import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'filters', 'basket']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose

export const store = createStore(persistedReducer, composeEnhancers(
  applyMiddleware(thunk)
))

const persistor = persistStore(store)

export default () => {
  return { store, persistor }
}
