import React from 'react'

import './RoundedInput.css'

const RoundedInput = ({ label, long, ...props }) => (
  <div className={`rounded-input-wrapper ${props.className || ''}`}>
    {label &&
      <label className="label"> {label}</label>
    }
    {long ? <textarea className="rounded-input" {...props}>{props.value}</textarea> :  <input className="rounded-input" {...props} />}

  </div>
)

export default RoundedInput
