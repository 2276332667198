import { combineReducers } from 'redux'

import user from './user/user.reducer'
import application from './application/application.reducer'
import products from './products/products.reducer'
import filters from './filters/filters.reducer'
import categories from './categories/categories.reducer'
import basket from './basket/basket.reducer'
import orders from './orders/orders.reducer'

const reducers = {
  user,
  application,
  products,
  filters,
  categories,
  basket,
  orders
}

export default combineReducers(reducers)
