import { createFactory, Component } from 'react'
import _ from 'lodash'

function getValueFromEvent (event) {
  if (event.target) {
    if (event.target.getAttribute && event.target.getAttribute('type') === 'checkbox') {
      return !!event.target.checked
    }

    return event.target.value
  }

  return event
}

const withFormHandlers = (initialData) => BaseComponent => {
  const factory = createFactory(BaseComponent)

  class WithFormHandlers extends Component {
    constructor (props) {
      super(props)

      const formData = _.isFunction(initialData) ? initialData(this.props) : initialData

      this.state = {
        formData: formData || {}
      }

      this.onChange = this.onChange.bind(this)
    }

    componentWillReceiveProps (nextProps) {
      if (_.isFunction(initialData)) {
        this.setState({ formData: initialData(nextProps) })
      }
    }

    onChange (field) {
      return (mayBeEvent) => {
        if (mayBeEvent && typeof mayBeEvent.persist === 'function') {
          mayBeEvent.persist()
        }

        this.setState((state) => ({
          formData: {
            ...state.formData,
            [field]: getValueFromEvent(mayBeEvent)
          }
        }))
      }
    }

    render () {
      return factory({
        ...this.props,
        ...this.state,
        onChange: this.onChange
      })
    }
  }

  return WithFormHandlers
}

export default withFormHandlers
