import React from 'react'

import { connect } from 'react-redux'

import { getMessages } from '../../store/application/application.selector'
import { deleteMessages as deleteMessagesAction } from '../../store/application/application.actions'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ErrorMessages = ({ messages, deleteMessages }) => {
  messages && messages.map(message => toast[message.level](message.message)) && deleteMessages()

  return (<ToastContainer />)
}

export default connect(store => ({
  messages: getMessages(store)
}), {
  deleteMessages: deleteMessagesAction
})(ErrorMessages)
