export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const RESET_BASKET = 'RESET_BASKET'

const initialState = {
  items: {},
  paymentMethod: 'transfer'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod
      }
    case RESET_BASKET:
      return {
        ...state,
        items: {}
      }
    case UPDATE_ITEM:{
      if (action.payload.cartons <= 0) {
        const newItems = Object.assign({}, state.items)

        delete newItems[action.payload.productId]

        return {
          ...state,
          items: newItems
        }
      }

      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.productId]: action.payload
        }
      }}
    default:
      return state
  }
}
