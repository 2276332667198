import React from 'react'

import logo from './logo.svg'

import './Footer.css'

const Footer = () => (
  <div className="footer-container">
    <div className="box logo"><img src={logo} alt="logo" /></div>
    <div className="box">Adatvédelmi irányelvek</div>
    <div className="box">Felhasználási feltételek</div>
    <div className="box"><b>Központi iroda:</b> 1141 Budapest, Mogyoródi u. 115.</div>
    <div className="box"><b>Email:</b> sales@popkft.hu</div>
    <div className="box"><b>Tel:</b> +36 1 467 0715</div>
    <div className="box copyright">&copy; 2018 P.O.P. Kft.</div>
  </div>
)

export default Footer
