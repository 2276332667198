export const LOGIN_STARTED = 'LOGIN_STARTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export const SET_USER = 'SET_USER'
export const SET_PARTNER = 'SET_PARTNER'

const initialState = {
  loggedIn: false,
  loggingIn: false,
  token: null,
  user: null,
  partner: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STARTED:
      return {
        loggingIn: true
      }
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        token: action.token
      }
    case LOGIN_FAIL:
      return {
        loggedIn: false
      }
    case LOGOUT:
      return {
        loggedIn: false
      }
    case SET_USER:
      return {
        user: action.user
      }
    case SET_PARTNER:
      return {
        partner: action.partner
      }
    default:
      return state
  }
}
