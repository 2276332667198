export const CHANGE_KEYWORD_FILTER = 'CHANGE_KEYWORD_FILTER'
export const CHANGE_CATEGORY_FILTER = 'CHANGE_CATEGORY_FILTER'
export const CHANGE_SHOWORDEREDONLY_FILTER = 'CHANGE_SHOWORDEREDONLY_FILTER'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'
export const OPEN_OR_CLOSE_FILTER_GROUP = 'OPEN_OR_CLOSE_FILTER_GROUP'

const initialState = {
  keyword: '',
  categories: [],
  showOrderedOnly: false,
  openGroups: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_KEYWORD_FILTER:
      return {
        ...state,
        keyword: action.keyword
      }
    case CHANGE_CATEGORY_FILTER:
      return {
        ...state,
        categories: (
          state.categories.includes(action.category)
            ? state.categories.filter(x => x !== action.category)
            : state.categories.concat([action.category])
        )
      }
    case CHANGE_SHOWORDEREDONLY_FILTER:
      return {
        ...state,
        showOrderedOnly: !state.showOrderedOnly
      }
    case RESET_ALL_FILTERS:
      return { ...initialState, openGroups: state.openGroups }
    case OPEN_OR_CLOSE_FILTER_GROUP:
      return {
        ...state,
        openGroups: (
          state.openGroups.includes(action.group)
            ? state.openGroups.filter(x => x !== action.group)
            : state.openGroups.concat([action.group])
        )
      }
    default:
      return state
  }
}
