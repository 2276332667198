import React from 'react'

import RoundedButton from '../common/RoundedButton'
import RoundedInput from '../common/RoundedInput'
import formEnhancer from '../../enhancers/formEnhancerBuilder'

import './ForgotPasswordForm.css'

function isValid (formData) {
  return formData.email
}

const ForgotPasswordForm = ({ onRequest, onLoginSelected, formData, onChange }) => (
  <form className="forgot-password-form"onSubmit={e => {
    e.preventDefault()

    if (isValid(formData)) {
      onRequest(formData.email)
    }
  }}>
    <h2>Elfelejtett jelszó</h2>

    <RoundedInput onChange={onChange('email')} placeholder="Email" />
    <RoundedButton type="submit" disabled={!isValid(formData)}>Küldés</RoundedButton>

    <a onClick={onLoginSelected} className="link">Vissza a belépéshez</a>
  </form>
)

export default formEnhancer()(ForgotPasswordForm)
