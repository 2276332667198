export const SET_TITLE = 'SET_TITLE'
export const SET_MESSAGE = 'SET_MESSAGE'
export const DELETE_MESSAGES = 'DELETE_MESSAGES'

const initialState = {
  title: '',
  messages: [],
  nextMessageId: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.title
      }
    case SET_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, {
          message: errorMessages[action.message] ? errorMessages[action.message] : action.message,
          level: action.level,
          id: state.nextMessageId
        }],
        nextMessageId: state.nextMessageId + 1
      }
    case DELETE_MESSAGES:
      return {
        ...state,
        messages: []
      }
    default:
      return state
  }
}

const errorMessages = {
  'credentials_failure': 'A rendszerben lejárt a belépési időtartama. Kérjük, jelentkezzen be újra!',
  'password-mismatch': 'Helytelen belépési adatok!',
  'inactive-user': 'A felhasználó jelenleg inaktív!',
  'server-error': 'Szerver hiba. Kérjük, próbálja meg újból!',
  'failed-to-send-to-omega': 'Nem sikerült rögzíteni a rendelést!',
  'email-already-exists': 'Az adott e-mail címhez már tartozik egy felhasználó!',
  'failed-to-send-mail': 'Az e-mailt nem sikerült elküldeni!',
  'user-not-found': 'Felhasználó nem található',
  'invalid-token': 'Érvénytelen token',
  'successfull-password-reset': 'Új jelszó sikeresen beállítva. Kérjük, jelentkezzen be!',
  'successfull-password-reset-email': 'Jelszó-emlékeztető e-mail kiküldve.',
  'order-success': 'Rendelés sikeresen véglegesítve'
}
