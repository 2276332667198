import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { LocalizationProvider } from 'react-localize'
import _ from 'lodash'

import { logout } from './store/user/user.actions'

import createStore from './store/createStore'
import configureAxios from './configureAxios'
import hu from './lang/hu.json'

import App from './App'

const { store, persistor } = createStore()

configureAxios(store)

function beforeStart () {
  const state = store.getState()

  const tokenExpiration = _.get(state, 'user.user.tokenExpiration')

  // Logout the user if the token is expired
  if (tokenExpiration && ((tokenExpiration * 1000) < new Date().getTime())) {
    store.dispatch(logout())
  }
}

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} onBeforeLift={beforeStart}>
      <LocalizationProvider messages={hu}>
        <App />
      </LocalizationProvider>
    </PersistGate>
  </Provider>
)

export default Root
