import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'

import { isAuthenticated } from './store/user/user.selector'

import ErrorMessages from './components/common/ErrorMessages'
import LoginContainer from './containers/LoginContainer'
import Footer from './components/footer/Footer'

const PartnerLayout = React.lazy(() => import('./containers/partner/PartnerLayout'))

class App extends Component {
  render () {
    const { isAuthenticated } = this.props

    return (
      <Router>
        <div className="app">
          <ErrorMessages />
          {!isAuthenticated && <LoginContainer />}

          {!!isAuthenticated &&
            <React.Fragment>
              <div className="content">
                <Suspense fallback={<div />}><PartnerLayout /></Suspense>
              </div>
              <Footer />
            </React.Fragment>
          }
        </div>
      </Router>
    )
  }
}

export default connect(store => ({
  isAuthenticated: isAuthenticated(store)
}))(App)
