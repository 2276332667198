import axios from 'axios'
import _ from 'lodash'

function handleHTTPErrors (object) {
  return Object.entries(object).reduce((acc, [key, func]) => {
    return {
      ...acc,
      [key]: async (...args) => {
        try {
          return await func(...args)
        }
        catch (error) {
          if (error.response) {
            throw _.isObject(error.response.data) ? error.response.data : { message: 'unknown-error' }
          }
          else if (error.request) {
            throw { message: 'network-error' }
          }
          else {
            throw { message: error.message }
          }
        }
      }
    }
  }, {})
}

export default handleHTTPErrors({
  // NEW WEBSHOP API
  async login ({ email, password }) {
    const { data } = await axios.post('/api/webshop/login', {
      email, password
    })

    return data
  },
  async fetchUser () {
    const { data } = await axios.get('/api/webshop/me')

    return data
  },
  async fetchPartner () {
    const { data } = await axios.get('/api/webshop/me/partner')

    return data
  },
  async fetchProducts () {
    const { data } = await axios.get('/api/webshop/products')

    return data
  },
  async fetchCategories () {
    const { data } = await axios.get('/api/webshop/product-categories')

    return data
  },
  async fetchOrders (page, perPage) {
    const { data: { data } } = await axios.get(`/api/webshop/orders?page=${page}&per_page=${perPage}`)

    return data
  },
  async finalizeOrder (type, addressId, comment, orderItems) {
    const { data } = await axios.post('/api/webshop/orders', {
      type,
      address_id: addressId,
      comment,
      order_items: orderItems
    })

    return data
  },
  async getReceiptPDF (type, id) {
    const { data } = await axios.get(`/api/webshop/orders/${type}/${id}/receipt`)

    return data
  },
  async requestPasswordReset (email) {
    const { data } = await axios.post('/api/webshop/password-reset/send', {
      email
    })

    return data
  },
  async resetPassword (resetToken, password) {
    const { data } = await axios.post('/api/webshop/password-reset', {
      token: resetToken,
      password
    })

    return data
  },
  // ?
  async fetchResetTokenData (resetToken) {
    const { data } = await axios.get(`/api/webshop/password-reset/${resetToken}`)

    return data
  },
})
