import React from 'react'
import { connect } from 'react-redux'
import { lifecycle, compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import RoundedInput from '../common/RoundedInput'
import RoundedButton from '../common/RoundedButton'

import formEnhancer from '../../enhancers/formEnhancerBuilder'

import {
  fetchResetTokenData as fetchResetTokenDataAction,
  resetPassword as resetPasswordAction
} from '../../store/user/user.actions'
import { getUser } from '../../store/user/user.selector'

import './ActivateRegistrationForm.css'

function isFormValid (formData, user) {
  return ((user) || formData.checked) &&
    formData.password &&
    (formData.password === formData.password2)
}

const ActivateRegistrationForm = ({ user, match, onChange, formData, resetPassword, onSuccess }) => (
  <form className="activate-registration-form"onSubmit={async (e) => {
    e.preventDefault()

    if (isFormValid(formData, user)) {
      await resetPassword(match.params.token, formData.password)
      onSuccess()
    }
  }}>
    <h2>Felhasználó aktiválása</h2>

    {user &&
      <React.Fragment>
        <RoundedInput value={user.name} disabled />
        <RoundedInput value={user.email} disabled />

        <RoundedInput onChange={onChange('password')} type="password" placeholder="Adja meg az új jelszavát" />
        <RoundedInput onChange={onChange('password2')} type="password" placeholder="Adja meg az új jelszavát mégegyszer" />

        <RoundedButton disabled={!isFormValid(formData, user)} type="submit">Tovább</RoundedButton>
      </React.Fragment>
    }
  </form>
)

export default compose(
  withRouter,
  connect(state => ({
    user: getUser(state)
  }), {
    fetchResetTokenData: fetchResetTokenDataAction,
    resetPassword: resetPasswordAction
  }),
  lifecycle({
    componentDidMount () {
      const token = this.props.match.params.token

      this.props.fetchResetTokenData(token)
    }
  }),
  formEnhancer()
)(ActivateRegistrationForm)
