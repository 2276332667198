import axios from 'axios'
import _ from 'lodash'
import { setMessage } from './store/application/application.actions'
import { logout } from './store/user/user.actions'

export default function (store) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  axios.interceptors.request.use(config => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: sessionStorage.getItem('token') ? `Bearer ${sessionStorage.getItem('token')}` : '',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }))

  axios.interceptors.response.use(null, (error) => {
    if (error.config && error.response) {
      if (error.response.status === 401) {
        store.dispatch(logout())
      }

      const errorMessage = _.get(error, 'response.data.message', 'Ismeretlen hiba történt!')

      store.dispatch(setMessage(errorMessage, 'error'))
    }

    return Promise.reject(error)
  })
}
