import React from 'react'

import RoundedButton from '../common/RoundedButton'
import RoundedInput from '../common/RoundedInput'
import formEnhancer from '../../enhancers/formEnhancerBuilder'

import './LoginForm.css'

function isValid (formData) {
  return formData.email && formData.password
}

const LoginForm = ({ onLogin, onForgottenPasswordSelected, formData, onChange }) => (
  <form className="login-form" onSubmit={e => {
    e.preventDefault()

    if (isValid(formData)) {
      onLogin(formData)
    }
  }}>
    <h2>Bejelentkezés</h2>

    <RoundedInput type="email" onChange={onChange('email')} placeholder="Email" autoComplete="username" />
    <RoundedInput type="password" onChange={onChange('password')} placeholder="Jelszó" autoComplete="current-password" />
    <RoundedButton type="submit">Bejelentkezés</RoundedButton>

    <a onClick={onForgottenPasswordSelected} className="link">Elfelejtett jelszó</a>
  </form>
)

export default formEnhancer()(LoginForm)
