export function isAuthenticated (state) {
  return state.user.loggedIn
}

export function getToken (state) {
  return state.user.token
}

export function getUser (state) {
  return state.user.user
}

export function getUserName (state) {
  return state.user.user.name
}

export function getPartner (state) {
  return state.user.user.partner
}

export function getAddresses (state) {
  const partner = getPartner(state)

  return partner.addresses && partner.addresses.length ? partner.addresses : []
}
