import React from 'react'

import './RoundedButton.css'

const RoundedButton = ({ className, bordered, color, dynamicWidth, size, ...props }) => (
  <button
    className={`rounded-button ${className || ''} ${bordered ? 'bordered' : ''} ${color || ''} ${dynamicWidth ? 'dynamicWidth' : ''} ${size || 'medium'}`}
    type={props.type || 'button'}
    {...props}
  >{props.children}</button>
)

export default RoundedButton
