import jsonwebtoken from 'jsonwebtoken'
import api from '../../api/api'
import { setMessage } from '../application/application.actions'

import { LOGIN_FAIL, LOGIN_STARTED, LOGIN_SUCCESS, LOGOUT, SET_PARTNER, SET_USER } from './user.reducer'

const loginStarted = () => ({ type: LOGIN_STARTED })
const loginSuccess = (user, token) => ({ type: LOGIN_SUCCESS, user, token })
const loginFail = () => ({ type: LOGIN_FAIL })
const setUser = (user) => ({ type: SET_USER, user })
const setPartner = (partner) => ({ type: SET_PARTNER, partner })

export const logout = () => {
  sessionStorage.removeItem('token')
  return { type: LOGOUT }
}

export function login ({ email, password }) {
  return async (dispatch) => {
    dispatch(loginStarted())

    try {
      const { token } = await api.login({ email, password })

      sessionStorage.setItem('token', token)
      const payload = jsonwebtoken.decode(token)

      const { data: user, exp: tokenExpiration } = payload

      const currentUser = await api.fetchUser()
      const partner = await api.fetchPartner()

      dispatch(loginSuccess({ ...user, partner, ...currentUser, tokenExpiration }, token))
    }
    catch (err) {
      console.error(err)
      dispatch(loginFail())
    }
  }
}

export function fetchPartner () {
  return async (dispatch, getState) => {
    try {
      const partner = await api.fetchPartner()

      dispatch(setPartner(partner))
    }
    catch (err) {
      console.error(err)
    }
  }
}

// user: {email, name}
export function fetchResetTokenData (resetToken) {
  return async (dispatch) => {
    const user = await api.fetchResetTokenData(resetToken)

    dispatch(setUser(user))
  }
}

export function resetPassword (resetToken, password) {
  return async (dispatch) => {
    await api.resetPassword(resetToken, password)

    dispatch(setMessage('successfull-password-reset', 'success'))
  }
}

export function requestPasswordResetEmail (email) {
  return async (dispatch) => {
    await api.requestPasswordReset(email)

    dispatch(setMessage('successfull-password-reset-email', 'success'))
  }
}
