import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'

import {
  login as loginAction,
  requestPasswordResetEmail as requestPasswordResetEmailAction
} from '../store/user/user.actions'

import LoginForm from '../components/login/LoginForm'
import ForgotPasswordForm from '../components/login/ForgotPasswordForm'
import ActivateRegistrationForm from '../components/login/ActivateRegistrationForm'

import './LoginContainer.css'

import logo from '../assets/logo.svg'

const routes = {
  LOGIN: '/',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  ACCOUNT_ACTIVATE: '/activate-registration/:token',
  PASSWORD_RESET: '/new-password/:token'
}

const LoginContainer = ({ login, requestPasswordResetEmail, history }) => (
  <div className="login-container">

    <div className="left">
      <img className="logo" src={logo} alt="logo" />

      <Switch>
        <Route exact path={routes.LOGIN} render={() =>
          <LoginForm onLogin={login} onForgottenPasswordSelected={() => history.push(routes.FORGOTTEN_PASSWORD)} />
        } />

        <Route exact path={routes.FORGOTTEN_PASSWORD} render={() =>
          <ForgotPasswordForm onRequest={requestPasswordResetEmail} onLoginSelected={() => history.push(routes.LOGIN)} />
        } />

        <Route exact path={routes.ACCOUNT_ACTIVATE} render={() =>
          <ActivateRegistrationForm onSuccess={() => history.push(routes.LOGIN)} />
        } />

        <Route exact path={routes.PASSWORD_RESET} render={() =>
          <ActivateRegistrationForm onSuccess={() => history.push(routes.LOGIN)} />
        } />

        <Redirect from="*" to="/" />
      </Switch>
    </div>

    <div className="right">
      <div className="welcome">
        <h2>Üdvözöljük</h2>
        <p>“Az élet olyan, mint egy doboz bonbon: az ember nem tudhatja, mit vesz belőle.”</p>
      </div>
    </div>
  </div>
)

export default compose(
  withRouter,
  connect(null, {
    login: loginAction,
    requestPasswordResetEmail: requestPasswordResetEmailAction
  })
)(LoginContainer)
